import React from "react";
import "./styles.scss";
import { Link } from "@reach/router";

const index = () => {
  return (
    <div className="startYourCareer">
      <div className="csm-container">
        <img className="girlIcon" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a7qdbb.png" loading="lazy" />
        <div className="header">Start your career with</div>
        <div className="csm">Content and Social Media Launchpad</div>
        <Link
          className="startYourCareer-csm"
          to={`/content-writing-course/`}
        >
          Apply Now
        </Link>
        <img className="suitcaseIcon" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2abqkmx.png" loading="lazy" />

      </div>
    </div>
  );
};

export default index;
