import React from "react";
import Slider from "react-slick";
import { trainersImages } from "./data";
import Card5 from "../../TestimonialCard/Card5-csm";
import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";

function CourseTracks() {
  const location = useLocation();

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <div className="csm-course-main-trainers mtop-7">
      <div className="will-be-training">
        <div className="trainers-container">
          <h2 className="training-section-title">
            {" "}
            Check out the details for the other courses{" "}
          </h2>
          <div className="new-underline">
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" loading="lazy" />
          </div>
          <span className="about-us-story-underline-img">
            <StaticImage
              src="https://kfwebassets.s3.ap-south-1.amazonaws.com/common/Group-11278.png"
              alt="title underlines"
              placeholder="none"
            />
          </span>

          <div className="csm-trainers-slider fb-track">
            <Slider {...settings}>
              {trainersImages.map((info, key) => (
                <Card5
                  key={key}
                  name={info.name}
                  title={info.title ? info.title : ''}
                  description={info.description}
                  subTitle={info.subTitle ? info.subTitle : ''}
                  link={info.link + location?.search}
                  mobSubTitle={info.mobSubTitle ? info.mobSubTitle : ''}
                  page={'csm'}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseTracks;
