import React, { useState } from "react";
import "./styles.scss";


function Fee() {
  const [item, setItem] = useState("placement-track");

  return (
    <div className="main-wrapper-csm-fee">
      <div className="csm-fee-header-div">
        <h2 className='csm-fee-heading'>Fees of the content writing course</h2>
        <img className="csm-fee-header-underline" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" loading="lazy" alt="Underline" />
      </div>
      <div className='wrapper2-csm-main mob-hide-csm'>
        <table>
          <thead>
            <tr>
              <th className="firstHead"></th>
              <th>Upskilling / <br/> Freelancing Track</th>
              <th className="lastHead">Placement Track</th>
            </tr>
          </thead>
          <tbody>
            {/* #1 */}
            <tr>
              <td>Program Fee (INR)</td>
              <td>₹ 55,000</td>
              <td>₹ 80,000 if paid upfront<br />₹ 90,000 if paid after training</td>
            </tr>

            {/* #2 */}
            <tr>
              <td>Learning Hours</td>
              <td>200 Hours</td>
              <td>200 Hours + Placement Interventions</td>
            </tr>

            {/* #3 */}
            <tr>
              <td>Program Duration</td>
              <td>16 weeks</td>
              <td>16 weeks + Placement Interventions</td>
            </tr>

            {/* #4 */}
            <tr>
              <td>Live Projects</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>

            {/* #5 */}
            <tr>
              <td>Portfolio Building</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>

            {/* #6 */}
            <tr>
              <td>Expert Feedback</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>

            {/* #7 */}
            <tr>
              <td>Dedicated Program Buddy</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>

            {/* #8 */}
            <tr>
              <td>Access to Leaders Unplugged Sessions</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>
            <tr>
              <td>Human Skills & Excel training</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>
            <tr>
              <td>Foundational CV & Interview Prep</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>
            <tr>
              <td>Personalized Interviews & Feedback</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>
            <tr>
              <td>Access to Kraftshala’s Alumni Benefits</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>
            <tr>
              <td>Access to Kraftshala’s Alumni network</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>
            <tr>
              <td>Eligibility for Content Challenges</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>
            <tr className='proprietary-row'>
              <td colSpan={3}>Kraftshala’s Proprietary Placement Support</td>
            </tr>
            <tr>
              <td>Placement Readiness Assessments</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aaul30.png" loading='lazy' alt="No" /></span>No</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>
            <tr>
              <td className="Placement-Bootcamp">Placement Bootcamp <br/><span style={{fontSize:"14px"}}>(Additional prep on CV, Portfolio & Interviews)</span></td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aaul30.png" loading='lazy' alt="No" /></span>No</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>
            <tr>
              <td>Access to Kraftshala’s Placement Platform</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aaul30.png" loading='lazy' alt="No" /></span>No</td>
              <td><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Yes</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='wrapper2-csm-main-mobile csm-pc-hide'>
        <div className="cover">
          <div className="menu">
            <div
              className={`menu-item ${item === "placement-track" ? "active" : ""}`}
              onClick={() => setItem("placement-track")}
            >
              Placement Track
            </div>
            <div
              className={`menu-item ${item === "upskilling" ? "active" : ""}`}
              onClick={() => setItem("upskilling")}
            >
              Upskilling/ <br />Freelancing Track
            </div>
          </div>
        </div>

        <div className="csm-fee-mob-body-wrapper ">
          {item === 'placement-track' && (
            <div className="csm-fee-mob-inside-div-1">
              <div className="csm-fee-mob-inside-subdiv-1">
                <div className="program-fee">
                  <h4 className="program-fee-text">Pogram Fee(INR)</h4>
                  <p>₹ 80,000 if paid for upfront  <br/>
                ₹ 90,0000 if paid for after training</p>
                </div>
                <div className="learning-hr">
                  <h4>Learning Hours</h4>
                  <p>150 Hours + Placement Interventions </p>
                </div>
                <div className="program-duration">
                  <h4>Program Duration</h4>
                  <p>16 weeks + Placement Interventions</p>
                </div>
              </div>
             
              <div className="csm-fee-mob-inside-div-2">
                <div>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Live Projects</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Portfolio Building</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Expert Feedback</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Dedicated Program Buddy</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Access to Leaders Unplugged Sessions</p>
                </div>

                <div className="div-2-body-middle">
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Foundational CV & Interview Prep</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>1-1 Interviews & Feedback</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Access to
               Alumni Benefits</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Access to
               Alumni network</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Human Skills & Excel training</p>
                </div>
              </div>

              <div className="csm-fee-mob-inside-div-3">
                <div><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a6gmdv.png" /></div>
                <div>
                  <h4>Kraftshala's Proprietary</h4>
                  <h4>Placement Support</h4>
                </div>
              </div>

              <div className="csm-fee-mob-inside-div-4">
                <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Placement Readiness Assessments</p>
                <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Placement Bootcamp <br/>
             (Additional prep on CV, Portfolio & Interviews)</p>
                <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Access to Placement Platform</p>
              </div>

            </div>
          )}

          {item === 'upskilling' && (
            <div className="upskilling-div-wrapper">
              <div className="upsklling-div1">
                <div className="upsklling-div1-sub-div1">
                  <p className="div1-programfee"> Program Fee(INR)</p>
                  <p className="div1-programfee" > ₹ 55,000 </p>
                </div>
                <div className="upsklling-div1-sub-div2">
                  <p>Learning Hours</p>
                  <p style={{marginLeft:"1.4em"}}>150 Hours</p>
                </div>
                <div className="upsklling-div1-sub-div3">
                  <p> Program Duration</p>
                  <p>16 weeks</p>
                </div>
              </div>

              <div className="upsklling-div2">
                <div className="upsklling-div2-sub-div1">
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Live Projects</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Portfolio Building</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Expert Feedback</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Dedicated Program <br/>Buddy</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Access to Leaders <br/>Unplugged Sessions</p>
                </div>
                <div className="upsklling-div2-sub-div2">
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Foundational CV & <br/>Interview Prep</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>1-1 Interviews & <br/>Feedback</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Access to <br/>
               Alumni Benefits</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Access to <br/>
               Alumni network</p>
                  <p><span><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2admhly.png" loading='lazy' alt="Yes" /></span>Human Skills & <br/> Excel training</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Fee;
