export const data = [
  {
    From: 'Frustration of what & how to learn',
    To: 'Structured classes every week for 16 weeks'
  },
  {
    From: 'Confusion of how to write',
    To: 'Writing with templates & frameworks built by experts'
  },
  {
    From: 'Fearing if AI will take over content jobs',
    To: 'Learning & using tools to 2X your career growth'
  },
  {
    From: 'Procrastinating completion of a write-up',
    To: 'Deadlines which keep you accountable'
  },
  {
    From: 'Writing & thinking alone',
    To: 'Writing with like-minded & equally ambitious peers'
  },
  {
    From: 'Wondering “is this good enough”',
    To: 'Getting direction & feedback from experts'
  },
  {
    From: 'Wondering what to write',
    To: 'Producing actual work through daily brand briefs'
  },
  {
    From: 'Learning only writing basics',
    To: 'Learning to succeed - excel, design, ppt, human skills etc'
  },
  {
    From: 'Getting only certificates as one more ‘CV point’',
    To: 'Getting a job of 4.5-9L in outstanding companies'
  }
];
  
  