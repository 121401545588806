import React from "react";

const ToSumItUpCard = ({from,to}) => {
  return (
    <div className="cards">
      <div className="card1">
        <div className="data">
          <span className="from">From: </span>
          {from}
        </div>
      </div>
      <img className="arrowDown" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a6oux1.png" loading="lazy" />
      <div className="card2">
        <div className="data">
          <span className="to">To: </span>
          {to}
        </div>
      </div>
    </div>
  );
};

export default ToSumItUpCard;
