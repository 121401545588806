
//company images


export const trainersImages = [
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28wjsx1.png",
    alt: "Kraftshala Digital Marketing Trainer_Varun_Satia",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28uoppr.png",
    alt: "Kraftshala Digital Marketing Trainer_Ishu_Sharma",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28vln00.webp",
    alt: "Kraftshala Digital Marketing Trainer_Praveen_Kasmas",
  },
  // {
  //   image: Shivang,
  //   alt: "Kraftshala Digital Marketing Trainer_Shivang_Sethiya",
  // },
  {
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28ul8b1.png",
    alt: "Kraftshala Digital Marketing Trainer_Ashi_Jain",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28ujn9j.png",
    alt: "Kraftshala Digital Marketing Trainer_Ankita_Gaba",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28vjpdj.png",
    alt: "Kraftshala Digital Marketing Trainer_Nrithya_Randhir",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28vo2jg.webp",
    alt: "Kraftshala Digital Marketing Trainer_Shraddha_More",
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28uqwff.webp",
    alt: "Kraftshala Digital Marketing Trainer_Kavita",
  },
  // {
  //   image: Warren,
  //   alt: "Kraftshala Digital Marketing Trainer_Warren_Luis",
  // },
 
];
