import React from "react";
import "./styles.scss";

function HomePageCard({ image, index, testimonial, ytLink }) {
  const boxStyle = {
    //background: `url(${image})`,
  };

  function YoutubeFn(){
    if(ytLink)
      window.open(ytLink,'_blank');
  }

  return (
    <>
      <div
        className="card-container-csm"
        style={boxStyle}
        onClick={ YoutubeFn }
        // type={index % 2 ? "even" : "odd"}
      >
        <div className="beforeHover">
          <img src={image} loading="lazy" />
        </div>
        <div class="hover">
          <img src={testimonial} />
        </div>
      </div>
    </>
  );
}

export default HomePageCard;
