import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.scss";

const index = () => {
  return (
    <div className=" csm-Admission-process">
      <img className="profileIcon" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2abalvq.png" loading="lazy" />
      <h2 className="Admission-process-header">
        Admission Process{" "}
        <img className="peopleIcon" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2ab95na.png" loading="lazy" />
        
        <br />
      </h2>
      <div className="new-underline">
        <StaticImage src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" />
      </div>
      <p className="admission-sub-heading">
        Our placement numbers matter a lot to us. And thus,
        <b>it's not a free pass.</b>  
      </p>

      <div className="second-div-admission">
        <div className="firstt-div">
          <img className="steps1" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2abm694.png" loading="lazy" />
          <img className="steps2" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2abkgpp.png" loading="lazy"  />
        </div>
        <div className="secondd-div">
          <p><b>Complete Application</b> </p>
          <p> Give the <b>Marketing Screening Test</b> where you decode an ad using  the concepts that we teach you</p>
          <p>If you clear the process,<b>enroll and <br/> start your journey!</b> </p>
        </div>
      </div>
    </div>
  );
};

export default index;
