import whiteBg from "./whiteBg.png";

export const stories = [
  {
    url: whiteBg
  },
  {
    url:
      "https://kfwebassets.s3.ap-south-1.amazonaws.com/videos/1st+Video+Part+A.mp4",
    type: "video",
    preloadResource: true,
  },
  {
    url:
      "https://kfwebassets.s3.ap-south-1.amazonaws.com/videos/1st+Video+Part+B.mp4",
    type: "video",
  },
  {
    url: "https://kfwebassets.s3.ap-south-1.amazonaws.com/videos/2nd+Video.mp4",
    type: "video",
  },
  {
    url: "https://kfwebassets.s3.ap-south-1.amazonaws.com/videos/3rd+Video.mp4",
    type: "video",
  },
];
