import React, { useState } from "react";
import { useRef, useEffect } from "react";
import Stories from "react-insta-stories";
import "./styles.scss"; // Import a CSS file for custom styles
import { stories } from "./data";

const InstaStory = ({ currentVideoIndex, setCurrentVideoIndex }) => {
  const videoRef = useRef(null);
  const [paused, setPaused] = useState(false);



  const handleAllStoriesEnd = () => {
    if (currentVideoIndex < stories.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    } else {
      // All stories have ended
      setCurrentVideoIndex(0);
    }
  };
  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          handlePause();
          setPaused(true);
        } else {
          setPaused(false);
        }
      });
    });

    const storySection = document.querySelector(".your-story-section-class");

    if (storySection) {
      observer.observe(storySection);
    }

    return () => {
      if (storySection) {
        observer.unobserve(storySection);
      }
    };
  }, []);

  return (
    <div className="your-story-section-class">
      <Stories
        progressContainerStyles={{
          marginTop: "-12px",
          filter: "drop-shadow(rgb(34, 24, 04) 0px 0px 0px)",
        }}
        progressStyles={{ background: "#f1ae13" }}
        progressWrapperStyles={{ background: "#d2d2d2" }}
        stories={stories}
        width={280}
        height={280}
        preloadResource={true}
        duration={10}
        defaultInterval={100}
        preventDefault={true}
        onPause={handlePause}
        onPlay={handlePlay}
        isPaused={paused}
        loop={true}
        currentIndex={currentVideoIndex}
        onAllStoriesEnd={handleAllStoriesEnd}
        onStoryEnd={handleAllStoriesEnd}
        onPreviousStory={handleAllStoriesEnd}
        onStoryChange={(index) => setCurrentVideoIndex(index)}
      />
    </div>
  );
};

export default InstaStory;
