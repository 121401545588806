export const data = {
  0: {
    learn: "Week 1",
    apply: "Week 1- Week 16",
    header: "First of all, you are a marketer!",
    list: [
      "Learn to break down business problems into marketing opportunities",
      "Learn how to conduct competitor research",
      "Learn about targeting the right audience for a business.",
      "Frame the communication objective, benefits, and the Big Idea",
      "Learn fundamentals of how to generate outputs with stellar design",
    ],
    text: "",
    // first: "Marketing Strategy for a student-grown apparel brand",
    // second: "Unleavables",
  },
  1: {
    learn: "Week 2",
    apply: "Week 2 - Week 16",
    header: "You next learn the foundations of making a content strategy",
    list: [
      "Learn the Brand Solutions Framework ",
      "Master the Content Strategy Framework",
      "Learn how to create Content Pillars",
    ],
    text: "",
    // first: "Brief creation for Mamaearth, John Jacobs",
    // second: "Blog Strategy + Blog publishing for at least 2 brands",
  },
  2: {
    learn: "Week 3 - Week 4",
    apply: "Week 3 - Week 9",
    Capstone: "Week 14 - Week 16",
    header: "Now, the most important social platform in India!",
    list: [
      "How to create content for Instagram",
      "Content pillars for Instagram",
      "Learn how to sharpen those pillars to generate 100+ posts using ChatGPT",
      "Learn how to write copy for stellar Image posts",
      "Learn how to write copy for stellar carousel, Reel scripts",
      "Learn how to convert text to image using AI prompts",
      "Learn how to create topical and calendar posts",
      "Learn how to create great visuals for your content",
      "Learn how to design great visuals on Mid Journey, Canva, Duet & more!"
    ],
    // first:
    //   "Instagram Content Calendar & Playbook for 2 brands: Decathlon, Bewakoof",
    // second: "Instagram publishing for at least 2 brands",
  },
  3: {
    learn: "Week 5",
    apply: "Week 4-Week 5",
    // header: "Get into the shoes of B2B Marketer!",
    list: [
      "Learn how to create content calendars",
      "Learn how to create a strategy brief for ads and social media",
      "Learn how to track for engagement metrics",
      "Learn how to partner with influencers to drive business objectives"
    ],
    text: "",
    // first:
    //   "Linkedin Strategy & post publishing for brands: Kraftshala, PickMyWork",
  },
  4: {
    learn: "Week 6-7",
    apply: "Week 6-7",
    Capstone:"Week 14 - Week 15",
    header: "Get into the shoes of a B2B Marketer!",
    list: [
      "Learn how to draw up a Content Strategy for a B2B brand",
      "Learn how to write copies for Linkedin post and ad formals",
      "Learn how to create a conversion funnel for a B2B brand using content and paid media"
    ],
    text: "",
    // first: "Youtube Strategy for a Live Brand: Unleavables",
    // second: "Youtube scripts for brands: Ditto Insurance, Kraftshala",
  },
  5: {
    learn: "Week 8-9",
    apply: "Week 8-9",
    header: "Milk this long-form video platform to push users down the funnel",
    list: [
      "Learn how to use the Hub-Hero-Hygiene model for YT strategy",
      "Learn how to conduct Keyword research to identify areas for your brand / category",
      "Learn how you can improve discoverability of your videos using titles, descriptions & more",
      "Learn how to script youtube videos",
      "Learn how to measure performance of Youtube to draw insights",
    ],
    text: "",
    // first:
    //   "Youtube Strategy for a Live Brand: Unleavables",
    // second: "Youtube scripts for brands: Ditto Insurance, Kraftshala"
  },
  6: {
    learn: "Week 10-12",
    apply: "Week 10-16",
    header:
      "Next, you've to get conversions for your brand through content",
    list: [
      "Identify blog spaces for your brand using keyword research & industry frameworks",
      "Learn how to categorize topics across the funnel to narrow basis the brand's objective",
      "Learn how to write headlines that convert",
      "Learn the Research Framework to Identify the juice for your blog using chatGPT, Google, Reddit, X & more",
      "Learn how to create the write-ups keeping in mind the brand tonality and brand’s CTA"
    ],
    text: "",
    // first:
    //   "Email strategy & content for brands:",
    // second: "Ditto Insurance, Kraftshala, Auric"
  },
  7: {
    learn: "Week 13",
    apply: "Week 13-16",
    header: "Rank your content at the top of Google Search",
    list: [
      "Introduction to SEO",
      "Learn how to create an SEO content growth plan",
      "Learn how to optimize your website pages for max traffic",
      "Learn the tools - On-page optimisation & internal linking",
      "Learn the auditing framework to improve an existing page's ranking",
      "Learn essential SEO tools"
    ],
    text: "",
  },
  8: {
    learn: "Week 14",
    apply: "Week 14",
    header: "You can't improve what you can't measure",
    list: [
      "Learn how to use instagram insights, youtube analytics, etc. to optimize your plans",
      "Learn how to use Google Console",
      "Learn how to use brand’s own analytics tools to narrow down on user behavior: Google Analytics",
      "Learn how to use data to your websites"
    ],
    text: "",
  },
  9: {
    learn: "Week 15",
    apply: "Week 15",
    header:
      "It's important to manage our reputation online!",
    list: [
      "Learn how to identify the intent & tonality of the commenter ",
      "Learn how to identify the job to be done and the brand's tonality ",
      "Learn how to delight users with your conflict resolution & empathy ",
    ],
    text: "",
  },
  10: {
    learn: "Week 15",
    apply: "Your own time",
    header: "Influencers have become a crucial part of the decision-making process! This module is available as a bonus",
    list: [
      "Learn how to understand the Strategy & Goals that influencer marketing is supposed to deliver for brands & business. ",
      "Learn how to plan an Influencer Campaign by defining the Objective & KPIs ",
      "Learn how to create a brief for influencers ",
      "Learn how to find the Right Influencers, reaching out and aligning with the Budget.",
      "Learn how to create a Posting Schedule, Setting up UTM links for every influencer and tracking performance",
      
    ],
    text: "",
    // first: "Comprehensive Organic roadmap brands:",
    // second: "Kraftshala, PickMyWork"
  },
  11: {
    learn: "Week 15",
    apply: "Week 15",
    header: "A crucial growth (and in no way 'dead') lever for all parts of the funnel.",
    list: [
      "Learn the framework to segment the subscriber list",
      "Learn how to plan and write Transactional, Promotional and Relational emails",
      "Learn how to analyze data for improving the email subject lines and copy",
    ],
    // text: "",
    // first: "Comprehensive Organic roadmap for brands: Kraftshala, PickMyWork",
  },
  12: {
    // learn: "Week 3-10",
    // apply: "Week 3-15",
    header: "Everything comes together here!",
    list: [
      "Learn how to create organic strategy for social across all platforms important for the brand ",
      "Learn how to create blog strategy and writing briefs for a website",
      "Learn how to finalise a plan and make it ready for alignment and execution",
    ],
    // first: "Comprehensive Organic roadmap brands:",
    // second: "Kraftshala, PickMyWork"
  },
  13: {
    learn: "All 16 Weeks",
    apply: "All 16 Weeks",
    header: "Crack interviews and ace at your job",
    list: [
      "Create a CV that highlights your experiences and achievements",
      "Share your journey in an impactful manner",
      "Prepare for an interview",
      "Create flawless work products such as email, presentations etc",
      "Communicate effectively using our RICE framework",
      "Be ready for the workplace",
    ],
    subText: "You learn how to",
    yourOutput: true,
    // first: "Showcase your analytical prowess in real-world digital marketing projects. Excel becomes your strategic ally!"
  },
  14: {
    learn: "Week 10",
    apply: "Week 10-16",
    header: "Elevate Your Marketing with Excel Expertise",
    list: [
      " Strategic Decision-Making: Sharpen your analytical thinking.",
      "Data Mastery: Gain insights using advanced analytical techniques.",
      "Lookup Techniques: Navigate and analyze data efficiently.",
      " Data Organization: Master the art of data structuring.",
      
    ],
    subText: "You learn how to",
    yourOutput: true,
    // first: "Showcase your analytical prowess in real-world digital marketing projects. Excel becomes your strategic ally!"
  },
};

export const headerData = [
  "Marketing Fundamentals",
  "Content Strategy Framework",
  "Instagram | Copy-writing",
  "Instagram | Performance",
  "Linkedin - Strategy & Execution",
  "Youtube - Strategy and Execution",
  "Blog writing",
  "SEO - Rank your content",
  "Content Measurement & Analysis",
  "Online Reputation Management (ORM)",
  "Influencer marketing",
  "Email Writing",
  "Capstone Project",
  "Human Skills",
  "Excel Skills"
];
