import React from "react";
import { data } from "./data";
import ToolsCard from "../../TestimonialCard/CsmPageCard/ToolsCard";
import Slider from "react-slick";

const ToolsMobile = () => {
  const settings = {
    prevArrow: "",
    dots: false,
    infinite: false,
    speed: 1000,
    //autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: true,
    slidesToShow: 1,
    //slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {data.map((info, key) => (
        <ToolsCard key={key} img={info.img} />
      ))}
    </Slider>
  );
};

export default ToolsMobile;
