import React from "react";
import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";
import ToolsMobile from "./ToolsMobile";

const Tools = () => {
  return (
    <div className="tools-covered csm-tools" id="tools">
      <div className="tools-header"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2ab2995.png" className="pencil" loading="lazy" />Tools you’ll learn  <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2abhdmr.png" className="setting" loading="lazy" /> <br/> <StaticImage className="under-line-New" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" /></div>
      <div className="tools-container">
        <div className="tools-row tools-pc">
          <div className="tools-title">Creation Tools +AI</div>
          <div className="tools">
            <div className="imageContainer big-img">
              <StaticImage src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28t3tu2.png" />
            </div>
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28tlq9f.png" />
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28tvtqp.png" className="stableFution" />
            <div className="imageContainer big-img">
              <StaticImage src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28t1p9s.png" />
            </div>
            <div className="imageContainer">
              <StaticImage src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28ta20c.png" />
            </div>
            <div className="imageContainer">
              <StaticImage
                style={{ maxWidth: "54px" }}
                className="dallE"
                src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28t84eu.png"
              />
            </div>
          </div>
        </div>
        <div className="tools-row tools-pc">
          <div className="tools-title">Content Platforms</div>
          <div className="tools">
            <div className="imageContainer">
              <StaticImage src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28tg2lz.png" />
            </div>
            <div className="imageContainer">
              <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28thldk.png" />
            </div>
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28tqizc.png" />
            <div className="imageContainer">
            </div>
            <div className="imageContainer">
              <StaticImage src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28u5nmo.png" />
            </div>
          </div>
        </div>
        <div className="tools-row tools-pc">
          <div className="tools-title">Publishing Platforms</div>
          <div className="tools">
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28tjm7l.png" />
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28u0nys.png" />         
            <div className="imageContainer">
              <StaticImage src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28tn3vy.png" />
            </div>
          </div>
        </div>
        <div className="tools-row tools-pc">
          <div className="tools-title">Writing Support</div>
          <div className="tools">
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28tefyh.png" />
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28tur5z.png" />
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28tbmmy.png" />
          </div>
        </div>
        <div className="tools-row tools-pc">
          <div className="tools-title">Operations</div>
          <div className="tools">
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28td3nw.png" className="gsuit" loading="lazy" />
          </div>
        </div>
        <div className="tools-mob">
          <ToolsMobile />
        </div>
      </div>
    </div>
  );
};

export default Tools;
