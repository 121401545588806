import React from "react";
import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";

function CourseBody({ course, handleDataFn }) {
  return (
    <div className="box-body">
      <div className="tablets">
        {course.learn && (
          <div className="tablet">
            <div className="icon yellow-bg">
              <StaticImage src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28z4evh.png" />
            </div>
            <div className="body">
              <div className="top">Learn in</div>
              <div className="btm">{course.learn}</div>
            </div>
          </div>
        )}
        {course.apply && (
          <div className="tablet">
            <div className="icon">
              <StaticImage src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2ab1clc.png" />
            </div>
            <div className="body">
              <div className="top">Apply During</div>
              <div className="btm">{course.apply}</div>
            </div>
          </div>
        )}
        
      </div>
      <div className="body-header">{course.header}</div>
      {course.text && <div className="body-text">{course.text}</div>}
      {course.text && <div className="sub-text">{course.subText}</div>}
      <div className="lists">
        <ul>
          {course.list.map((item) => (
            <li>
              <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a7uey9.png" loading="lazy" />
              <span className="list-content" dangerouslySetInnerHTML={{ __html: item }}/>
            </li>
          ))}
        </ul>
      </div>
      {course.first && (
        <div className="milestone">
          <div className="icon">
            {!course.yourOutput ? <><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a92omy.png" loading="lazy" /> Portfolio Milestone:{" "}</> : 'Your Outputs:' }
          </div>
          <div className="content">
            <div className="name-holder">{course.first}</div>
            <div className="descr">{course.second}</div>
          </div>
        </div>
      )}
      {course.first && (
        <div className="milestone pc-none">
          <div className="location">
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a92omy.png" loading="lazy" /> 
          </div>
          <div className="text-sec">
            <div className="icon">Portfolio Milestone: </div>
            <div className="content">
              <div className="name-holder">{course.first}</div>
              <div className="descr">{course.second}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseBody;
