import React from "react";
import "./styles.scss";

/*Card for Student Testemonial*/
function Card5({ name, description, link, title, subTitle, page }) {
  return (
    <div className="card-type5-section student-section">
      {page !== "basl" ? (
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2adl1l1.png" className="yellowTicket" loading="lazy" />
      ) : (
        <div className="yellowTicket-box"></div>
      )}
      <div className="type2-info">
        <div className="name">{name}</div>
        <div className="title">{title}</div>
        {subTitle && <div className="subTitle">{subTitle}</div>}
        <div className="type2-description1">{description}</div>
        <div className="dbb-program-card-btn">
          <a target="_blank" rel="noreferrer" href={link}>
            Explore Program
          </a>
        </div>
      </div>
    </div>
  );
}

export default Card5;
