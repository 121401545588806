import React, { useEffect, useState } from "react";
import "./styles.scss";
import InstaStory from "./InstaStory";


const index = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0); // State to track the current video index

  useEffect(() => {
    if(currentVideoIndex === 0){
      setCurrentVideoIndex(1);
    }
  },[currentVideoIndex]);

  return (
    <div className="Ai-learning">
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a5ov6i.png" className="dottedBgPc" alt="" />
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28yizgq.png" className="AiDottedRightPc" alt="" />
      <div className="subTitle">AI FOR CONTENT WRITERS</div>
      <div className="ai-title">
        You will also 2X your career growth by learning AI
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" className="ai-underline" loading="lazy" />
      <div className="ai-desc">
        We’ll help you learn how to use AI as your sidekick in your content
        writing journey to <br />
        <b>double your productivity and creativity.</b>
      </div>
      <div className="flex-container">
        <div className="story-board">
          <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2adl1l1.png" className="yellow-ticket" />
          <InstaStory
            setCurrentVideoIndex={setCurrentVideoIndex}
            currentVideoIndex={currentVideoIndex}
          />
        </div>
        {(currentVideoIndex === 1 || currentVideoIndex === 2) && (
          <div className="story-desc">
            <div className="story-header">Idea Machine for Social</div>
            <div className="ul">
              <div className="story-item"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28pdedb.png" className="story-icon" loading="lazy" />Divergent thinking</div>
              <div className="story-item"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28ph2qw.png" className="story-icon" loading="lazy" />Broad base of references</div>
              <div className="story-item"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28pruln.png" className="story-icon" loading="lazy" />Across industries</div>
            </div>
          </div>
        )}
        {currentVideoIndex === 3 && (
          <div className="story-desc">
            <div className="story-header">Be a Design Ninja</div>
            <div className="ul">
              <div className="story-item"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28pkxpw.png" className="story-icon  imgIcon" loading="lazy" />Text to image tools</div>
              <div className="story-item"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28pjfzh.png" className="story-icon" loading="lazy" />Multilingual content</div>
              <div className="story-item"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28pojqx.png" className="story-icon personIcon" loading="lazy" />Personalized creatives</div>
            </div>
          </div>
        )}
        {currentVideoIndex === 4 && (
          <div className="story-desc">
            <div className="story-header">Keyword Research for Blogs</div>
            <div className="ul">
              <div className="story-item"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28pbkq3.png" className="story-icon" loading="lazy" />Analyse data</div>
              <div className="story-item"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28pps2c.png" className="story-icon researchIcon" loading="lazy" />Keyword Research</div>
              <div className="story-item"><img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28pfw17.png" className="story-icon" loading="lazy" />Competitor analysis</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default index;
