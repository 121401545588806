


export const Data = [
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a5z2o1.png",
    clip: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm291traq.png"
    ,

    title: "The Aspiring Creators",
    desc: `<li>If you want to be in a creative career but unsure of how to start</li>
    <br/>
    <li>If you dont know whether you can make a career out of this</li>`,
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a60qsp.png",
    clip: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a4j3bs.png",
    title: "The Forever Learners",
    desc: `<li>If you are already in marketing &  want to learn content creation</li>
             <br/>
    <li>If you want to build structure and nurture that creative side</li>`,
  },
  {
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a62y7j.png",
    clip: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm291traq.png"
    ,
    title: "The SOLO-Prenuers",
    desc: `<li>If you are building something - be it your personal brand / business</li>
   <br/>
    <li>If you want to get perspectives on your business from experts</li>`,
  },
  {
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a64hr9.png",
    clip: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a4j3bs.png",
    title: "The Career Transitioners",
    desc: `<li> If you want to make a shift into a creative career</li>
    <br/>
    <li>If you want to get into amazing  content and social media roles</li>`,
  },
];
