import React from "react";

const ToolsCard = ({img}) => {
  return (
    <div className="tools-row tools-mob">
      <img src={img} loading="lazy" />
      
    </div>
  );
};

export default ToolsCard;
