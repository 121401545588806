import React, { useEffect, useRef, useState } from "react";
import Seo from "../../components/Seo/seo";
import Layout from "../../components/Layout/layout";
import HeroSection from "../../components/CSMFormComp/HeroSection/heroSection";
import MLPForm from "../../components/Forms/CSMForm";
import NavBar from "../../components/NavBar/navbar";
//import NavBar from "../../components/NavBar/navbar";
// import Overview from "../../../components/CSMMainComp/Overview/overview";
// import OverviewTitle from "../../components/CSMMainComp/Overview/OverviewTitle";
import Placements from "../../components/CSMMainComp/Placements/placements";
import PlacementReport from "../../components/CSMMainComp/PlacementReport";
import SumItAllUp from "../../components/CSMMainComp/SumItAllUp";
import CareerTajectory from "../../components/CSMMainComp/CareerTajectory";
import Fee from "../../components/CSMMainComp/Fee/fee";
import AdmissionProcess from "../../components/CSMMainComp/AdmissionProcess/admissionProcess";
import Tools from "../../components/CSMMainComp/ToolsCovered";
import StartYourCareer from "../../components/CSMMainComp/StartYourCareer";
//import WhatsappWidget from "../../../components/WhatsappWidget";
import Alumni from "../../components/CSMMainComp/Alumni/AlumniCarousal";
import CourseStructure from "../../components/CSMMainComp/courseStructure/courseStructure";
import Trainers from "../../components/CSMMainComp/Trainers/trainers";
import CourseTracks from "../../components/CSMMainComp/Tracks/CourseTracks";
import Faq from "../../components/CSMMainComp/FAQS/faq";
import AiLearning from "../../components/CSMMainComp/AI";
import Floater from "../../components/CSMMainComp/Floater/Floater";

import {
  videoSnipptJSON,
  eventSnipptJSON,
} from "../../components/Seo/csmSeoData";

import "./styles.scss";
import IvrSection from "../../components/CSMMainComp/Ivr";
import ContactUsPopup from "../../components/ContactUsPopup";
import CourseFor from "../../components/CSMMainComp/CouseFor/CourseFor";

const MarketingLaunchPad = (props) => {
  const navBarContent = [
    {
      name: "Benefits",
      id: "Benefits",
    },
    {
      name: "Curriculum",
      id: "curriculum",
    },
    {
      name: "Faculty",
      id: "Faculty",
    },
    {
      name: "Placements",
      id: "Placements",
    },
    {
      name: "Admissions",
      id: "Admissions",
    },
    {
      name: "Fee",
      id: "Fee",
    },
    {
      name: "FAQS",
      id: "faqs",
    },
    {
      name: "Apply Now",
      id: "hero-div",
    },
  ];

  const [isHidden, setIsHidden] = useState(true);

  const [showContactUsPopup, setContactUsPopup] = useState(false);

  const contactUsTimeout = useRef(null);

  useEffect(() => {
    contactUsTimeout.current = setTimeout(() => {
      setContactUsPopup(true);
    }, 40 * 1000);

    function handleScroll() {
      const currentScrollPosition = window.pageYOffset;

      setIsHidden(currentScrollPosition < 1400); // Change 100 to the number of pixels after which you want to hide the element
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      if (contactUsTimeout.current) clearTimeout(contactUsTimeout.current);
    };
  }, []);

  return (
    <React.Fragment>
      <Seo
        title="India’s #1 Content Writing Course With Placement - Kraftshala"
        description="Enroll into India’s top content writing course with 98% placement rate. Learn content writing, social media copywriting, influencer marketing and much more. Apply today!"
        url="https://www.kraftshala.com/content-writing-course/"
        // courseSnipptJSON={courseSnipptJSON}
        // faqSnipptJSON={faqSnipptJSON}
        videoSnipptJSON={videoSnipptJSON}
        eventSnipptJSON={eventSnipptJSON}
      />
      {showContactUsPopup && <ContactUsPopup />}
      <div className="main-csm-page">
        <Layout formLink="/content-writing-course">
          <div className="hero-div" id="hero-div">
            <HeroSection urlParams={props.location.search} />
            <MLPForm search={props.location.search} />
          </div>

          <div id="floater" className="section">
            <Floater />
          </div>
   

          {/* <div className={isHidden ? "fixed-bottomHidden" : "fixed-bottom"}>
              {!isHidden && (
                <>
                  {" "}
                  <button
                    className={isHidden ? "fixed-bottomHidden" : "footer-btn"}
                    onClick={() => {
                      document.querySelector("#hero-div").scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "nearest",
                      });
                    }}
                  >
                    Start my marketing career
                  </button>
                </>
              )}
            </div> */}

          {/* <OverviewTitle /> */}

          <div id="alumni" className="section">
            <Alumni />
          </div>

          <NavBar
            offset={1730}
            content={navBarContent}
            applyLink={`/content-writing-course/${props.location.search}`}
          />
          <div className="csm-main-overview">
            
            <div id="CourseFor" className="section">
              <CourseFor />
            </div>


            <div id="Benefits" className="section">
              <Placements />
            </div>

            <div id="curriculum" className="section">
              <CourseStructure urlParams={props.location.search} />
            </div>

            <div className="pt-5" id="csmTools">
              {" "}
              <Tools />
            </div>
            <div id="Faculty" className="section">
              <Trainers />
            </div>

            <div id="Placements" className="section">
              <PlacementReport />
            </div>

            <div id="experts" className="section">
              <CareerTajectory />
            </div>

            <div id="learning" className="section">
              <AiLearning />
              <SumItAllUp />
            </div>

            <div id="Admissions" className="section">
              <AdmissionProcess />
            </div>

            <div id="Fee" className="section">
              <Fee />
              {/* <FeeSlider /> */}
            </div>

            <StartYourCareer />

            <CourseTracks />

            <div id="faqs" className="section">
              <Faq />
            </div>
            <div id="ivr" className="section">
              <IvrSection />
            </div>
            <div
              id="apply now"
              className="section"
              urlParams={props.location.search}
            ></div>
          </div>
        </Layout>
      </div>

      {/* <WhatsappWidget link="https://wa.me/message/HHNAOZDIMB4ZE1" /> */}
    </React.Fragment>
  );
};

export default MarketingLaunchPad;
